import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VRow,{staticClass:"mt-4"},[_c(VCol,{attrs:{"sm":"6"}},[_c(VCard,[_c(VListItem,[_c(VListItemContent,[_c(VIcon,{staticClass:"clickable pt-4",attrs:{"color":"primary","size":"55"}},[_vm._v("mdi-account-circle")])],1)],1),_c(VCardText,[_c('p',{staticClass:"subheading font-weight-regular text-center"},[_vm._v("Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.")])])],1)],1),_c(VCol,{attrs:{"sm":"6"}},[_c(VCard,[_c(VListItem,[_c(VListItemContent,[_c(VIcon,{staticClass:"pt-4 xl-icon",attrs:{"color":"primary","size":"55"}},[_vm._v("mdi-contact-mail")])],1)],1),_c(VCardText,[_c('p',{staticClass:"subheading font-weight-regular text-center"},[_vm._v("Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }