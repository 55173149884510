<template>
  <v-container>
    <v-row class="mt-4">
      <v-col sm="6">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-icon class="clickable pt-4" color="primary" size="55"
                >mdi-account-circle</v-icon
              >
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <p class="subheading font-weight-regular text-center"
              >Lorem Ipsum has been the industry's standard dummy text ever
              since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged.</p
            >
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6">
        <v-card>
          <v-list-item>
            <v-list-item-content>
              <v-icon class="pt-4 xl-icon" color="primary" size="55"
                >mdi-contact-mail</v-icon
              >
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <p class="subheading font-weight-regular text-center"
              >Lorem Ipsum has been the industry's standard dummy text ever
              since the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged.</p
            >
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {}
</script>
<!-- STYLES -->
<style lang="scss" scoped>
//@import '@design';
</style>

